<template>
  <ul class="private-wapper message-ul">
    <li v-for="(item, index) in notice.data" :key="index">
      <router-link
      v-if="item.sendUserId"
        :to="{ name: 'Personal', query: { uid: item.sendUserId, type: 'mine' } }"
      >
        <img
          class="private-avatar"
          :src="formatAvatar(item.sendUserId, item)"
          alt=""
        />
      </router-link>
          <a v-else
      >
        <img
          class="private-avatar"
          :src="formatAvatar(item.sendUserId, item)"
          alt=""
        />
      </a>
      <div class="private-date">
        {{ dateFormat(item.createTime) }}
      </div>
      <div class="user-name">{{ item.user.userName?item.user.userName:'北纳生物' }}</div>
      <div class="private-message">
        {{ item.noticeContent }}

      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dateFormat from "@/utils/dateFormat";
export default defineComponent({
  name: "Notice",
  props: {
    notice: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    function formatAvatar(id: string, item: any) {
      let src = "";
      if (!Number(id)) {
        src =
          "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg";
      } else {
        src = item.user.userImg;
      }
      return src
    }
    return {
      dateFormat,
      formatAvatar,
    };
  },
});
</script>

<style lang="scss" scoped>
/*
    私信内容
  */

.private-wapper {
  width: 355px;
  margin: 0 auto;
}
.private-wapper li {
  padding: 20px 10px;
  box-sizing: border-box;
  position: relative;
  padding-left: 70px;

  border-bottom: 6px solid #fff;
}
.private-avatar {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 10px;
  top: 20px;
  border-radius: 50%;
}

.user-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #444444;
  font-size: 15px;
  margin: 5px 0;
  margin-top: 7.5px;
}

.private-date {
  color: #999999;
  font-size: 14px;
}
/*
    点赞
  */

.support-wapper {
  width: 355px;
  margin: 0 auto;
  li {
    padding: 20px 10px;
    box-sizing: border-box;
    border-bottom: 6px solid #fff;

    .support-date {
      color: #999999;
      font-size: 14px;
    }
    .support-content {
      color: #999999;
      font-size: 15px;
      margin-top: 15px;
      position: relative;
    }
    .supporter-avatar {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      vertical-align: middle;
    }
    .supporter-name {
      margin-left: 10px;
      width: 75px;
      display: inline-block;
      vertical-align: middle;
      line-height: 27px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #444444;
      font-size: 14px;
    }
  }
}
.private-message {
  overflow: hidden;
  word-break: break-all;
  color: #999999;
  font-size: 14px;
  position: relative;
  .support-icon {
    width: 14.5px;
    height: 14.5px;

    position: absolute;
    right: 0;
  }
}
</style>